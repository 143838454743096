<template>
  <div class="dialogWrapper">
    <div v-if="this.response === 0" class="dialogWindow">
      <h1>Unesite novu lozinku</h1>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="password"
            color="#137547"
            outlined
            rounded
            label="Nova lozinka"
            placeholder="Unesite novu lozinku"
            class="vtxt"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
            :rules="passwordRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="repeatedPassword"
            color="#137547"
            outlined
            rounded
            label="Ponovite lozinku"
            placeholder="Ponovo unesite lozinku"
            class="vtxt"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
            :rules="repeatedPasswordRules"
            required
          ></v-text-field>
          <button class="submitButton" @click.prevent="submitForm">Pošalji novu lozinku</button>
        </v-form>
      </v-card-text>
    </div>
    <div v-else-if="this.response === 200" class="dialogWindow">
      <v-card-text> Vaša lozinka je uspešno promenjena. </v-card-text>
    </div>
    <div v-else-if="this.response > 399" class="errorWindow">
      <v-card-text class="errorMessage"> Došlo je do greške. </v-card-text>
      <button class="tryAgainButton" @click.prevent="reset">Pokušajte ponovo</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      repeatedPassword: "",
      hash: this.$route.params.hash,
      response: 0,
      show2: false,
      passwordRules: [(v) => !!v || "Lozinka je obavezna"],
      repeatedPasswordRules: [(v) => !!v || "Lozinka je obavezna", (v) => v === this.password || "Lozinke se ne poklapaju"],
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        let newPassword = {
          hash: this.hash,
          password: this.password,
        };
        await axios
          .post("ForgottenPassword/ChangePassword", newPassword)
          .then((response) => {
            this.response = response.status;
          })
          .catch(() => {
            this.response = 400;
          });
      }
    },
    reset() {
      this.password = "";
      this.repeatedPassword = "";
      this.response = 0;
      this.show2 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogWindow {
  width: 600px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 2rem 1rem;
}

.errorWindow {
  width: 600px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 1rem;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  text-align: center;
}

h1 {
  text-align: center;
}

.submitButton {
  width: 100%;
  padding: 0.8rem;
  color: #fff;
  background-color: #137547;
  border-radius: 50px;
}

.tryAgainButton {
  width: 40%;
  padding: 0.8rem;
  border-radius: 50px;
  border: 1px solid #137547;
  color: #137547;
}
</style>
